// react
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import classNames from 'classnames';

import Footer from './footer';
import Header from './header';
import PageBreadcrumbs from './shared/PageBreadcrumbs';
import MobileHeader from './mobile/MobileHeader';
import MobilePanel from './mobile/mobilePanel';
import theme from '../data/theme';
import BlogSideBar from './cms_components/sidebar/BlogSidebar'

function Layout(props) {
    const {
        match,
        headerLayout,
        homeComponent,
        showslider,
        breadcrumbs,
        hasSidebar,
        sidebarPosition,
        locale,
    } = props;

    const classesContainer = classNames(hasSidebar === 'true' ? 'container' : '');
    const classesRow = classNames(hasSidebar === 'true' ? 'row' : '');
    const classesCol = classNames(hasSidebar === 'true' ? 'col-12 col-lg-9' : '');
    const classesMT = classNames(breadcrumbs === 'false' ? 'mt-55' : '');

    let title
    if (locale === 'sq') {
        title = (
            <title>{theme.name_shq}</title>
        )
    } else if (locale === 'en') {
        title = (
            <title>{theme.name_en}</title>
        )
    } else if (locale === 'sr') {
        title = (
            <title>{theme.name_sr}</title>
        )
    }

    let sidebarStart;
    let sidebarEnd;

    const sidebar = <BlogSideBar position={sidebarPosition} />;

    if (sidebarPosition === 'start') {
        sidebarStart = (
            <div className={`col-xl-3 col-lg-3 sidebar--${hasSidebar}`}>
                <div className={classesMT}>
                    {sidebar}
                </div>
            </div>
        );
    } else if (sidebarPosition === 'end') {
        sidebarEnd = (
            <div className={`col-xl-3 col-lg-3 sidebar--${hasSidebar}`}>
                <div className={classesMT}>
                    {sidebar}
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                {title}
                <meta name="description" content={theme.fullName} />
            </Helmet>

            <ToastContainer autoClose={5000} hideProgressBar />

            <MobilePanel layout={headerLayout} />

            <div className="site">
                <header className="site__header ds-lg-none">
                    <MobileHeader />
                </header>

                <header className="site__header ds-lg-block d-none">
                    <Header layout={headerLayout} showslider={showslider} />
                </header>

                <div className="site__body">
                    {breadcrumbs === 'true' ? (
                        <div className={`breadcrumbs--${breadcrumbs}`}>
                            <PageBreadcrumbs />
                        </div>
                    ) : ('')}
                    <div className={classesContainer}>
                        <div className={classesRow}>
                            {sidebarStart}
                            <div className={classesCol}>
                                <Switch>
                                    <Route exact path={`${match.path}`} component={homeComponent} />
                                </Switch>
                            </div>
                            {sidebarEnd}
                        </div>
                    </div>

                </div>

                <footer className="site__footer">
                    <Footer />
                </footer>
            </div>
        </React.Fragment>
    );
}

Layout.propTypes = {
    headerLayout: PropTypes.oneOf(['default', 'about']),
    breadcrumbs: PropTypes.oneOf(['true', 'false']),
    hasSidebar: PropTypes.oneOf(['true', 'false']),
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
    homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
    headerLayout: 'default',
    breadcrumbs: 'true',
    hasSidebar: 'true',
    sidebarPosition: 'end',
};
const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(Layout);
