// react
import React from 'react';
import Moment from 'moment';
import 'moment/locale/sq';
import 'moment/locale/sr';
// third-party
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function EventList(props) {
    const { post, layout } = props;
    const { locale } = props;
    const cardClasses = classNames(
        'post-card event-card',
        {
            'post-card--layout--grid': ['grid-nl', 'grid-nl-4', 'grid-lg'].includes(layout),
            'post-card--layout--list': ['list-nl', 'list-sm'].includes(layout),
            'post-card--size--nl': ['grid-nl', 'grid-nl-4', 'list-nl'].includes(layout),
            'post-card--size--lg': layout === 'grid-lg',
            'post-card--size--sm': layout === 'list-sm',
        },
    );
    return (
        <div className={cardClasses}>
            <Link to={post.url}>
            <div className="post-card__info date-card__info">
                <div>
                    <div className="post-card__name event-card__name">
                        {post.postimiTitulli}
                    </div>
                    <ul className="news-metaa lajme-lista-date">
                        <li className="calendar-date">
                            <i className="far fa-calendar-alt" />
                            &nbsp;
                            <p className="date-lajme">
                                {Moment(post.postimiDataFillimit).locale(locale).format('LL')}
                            </p>
                        </li>
                    </ul>
                    <div className="post-card__contentt">
                            <p>
                                {post.postimiPershkrimi}
                            </p>
                    </div>
                </div>
            </div>
            </Link>
        </div>
    );
}

EventList.propTypes = {
    layout: PropTypes.oneOf(['grid-nl', 'grid-nl-4', 'grid-lg', 'list-nl', 'list-sm']),
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(EventList);
