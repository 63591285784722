// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// import { Link } from 'react-router-dom';
import StroykaSlick from '../shared/StroykaSlick';
import { getData } from '../../services/fetch-service';

// application
import departmentsAria from '../../services/departmentsArea';
import BlockHeader from '../shared/BlockHeader';
// import languages from '../../i18n';

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
};

class BlockSlideShow extends Component {
    departmentsAreaRef = null;

    media = window.matchMedia('(min-width: 992px)');

    constructor(props) {
        super(props);
        this.state = {
            slides: [],
        };
    }

    componentDidMount() {
        const { locale } = this.props;
        getData(`Home/GetSlides?Gjuha=${locale}&PageID=1`).then((response) => {
            this.setState({
                slides: response.data,
            });
        });
        if (this.media.addEventListener) {
            this.media.addEventListener('change', this.onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            this.media.addListener(this.onChangeMedia);
        }
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener('change', this.onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = (ref) => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    handleNextClick = () => {
        if (this.slickRef) {
            this.slickRef.slickNext();
        }
    };

    handlePrevClick = () => {
        if (this.slickRef) {
            this.slickRef.slickPrev();
        }
    };

    setSlickRef = (ref) => {
        this.slickRef = ref;
    };

    render() {
        const { withDepartments } = this.props;
        // const { direction } = languages[locale];

        const blockClasses = classNames(
            'block-slideshow block',
            {
                'block-slideshow--layout--full': !withDepartments,
            },
        );

        const layoutClasses = classNames(
            'col-12 kolonaa slider-home',
            {
                'full-slide': !withDepartments,
            },
        );
        const { slides } = this.state;
        const path = process.env.REACT_APP_FILESPATH;
        const slidess = slides.map((slide, index) => {
            const image = slide.media?.otherSource === null ? `${path}${slide.media?.mediaEmri}${slide.media?.mediaEx}` : slide.media?.otherSource;

            return (
                <div key={index} className="block-slideshow__slide">
                    {/* <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                        style={{
                            backgroundImage: `url(${image})`,
                        }}
                    /> */}
                    <div className="block-slideshow__slide-image block-slideshow__slide-image--desktop">
                        <LazyLoadImage
                            alt=""
                            effect="blur"
                            placeholderSrc="/images/blur/default-home.jpg"
                            src={slide.media.otherSource === null ? image : slide.media.otherSource}
                        />
                    </div>
                    <div className="slider-caption">
                        <div className="container slider-text">
                            <a className="titulli-slider" href={slide.linku}>{slide.sllideTitulli}</a>
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div className={blockClasses}>
                <div>
                    <div className="row rreshtiii">
                        {withDepartments && (
                            <div className="col-3 d-lg-block d-none" ref={this.setDepartmentsAreaRef} />
                        )}

                        <div className={layoutClasses}>
                            <div className="block-slideshow__body">
                                <StroykaSlick
                                    ref={this.setSlickRef}
                                    {...slickSettings}
                                >
                                    {slidess}
                                </StroykaSlick>
                            </div>
                            <BlockHeader
                                arrows
                                onNext={this.handleNextClick}
                                onPrev={this.handlePrevClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BlockSlideShow.propTypes = {
    withDepartments: PropTypes.bool,
    /** current locale */
    locale: PropTypes.string,
};

BlockSlideShow.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockSlideShow);
