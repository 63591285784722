// react
import {
    React,
    useState,
    useEffect,
    Fragment,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { getData } from '../../services/fetch-service';

function BlockSlideBox(props) {
    const { locale, location } = props;
    const [allLinks, setAllLinks] = useState([]);
    const [menuname, setMenuName] = useState([]);

    if (location === 'home') {
        useEffect(() => {
            getData(`Home/GetMenus?&Gjuha=${locale}&LocationMenuID=7&ParentMenuID=0`).then((response) => {
                setAllLinks(response.data);
                setMenuName(response.data[0].menuLocationName);
            })
        }, []);
    } else if (location === 'slider') {
        useEffect(() => {
            getData(`Home/GetMenus?&Gjuha=${locale}&LocationMenuID=11&ParentMenuID=0`).then((response) => {
                setAllLinks(response.data);
            })
        }, []);
    } else if (location === 'aboutus') {
        useEffect(() => {
            getData(`Home/GetMenus?&Gjuha=${locale}&LocationMenuID=10&ParentMenuID=0`).then((response) => {
                setAllLinks(response.data);
            })
        }, []);
    }
    const linksList = allLinks.map((item, index) => {
        const path = process.env.REACT_APP_FILESPATH;
        const image = `${path}${item.media[0]?.mediaEmri}${item.media[0]?.mediaEx}`;
        return (
            <li key={index}>
                {item.hasChild === true ? (
                    <div className="slider-box-link slider-box-link-childs">
                        <img src={image} alt="" />
                        <div className="link-childs-list">
                            <div className="and-devider">
                                <Link to={item.url}>
                                    {item.pageName}
                                </Link>
                                <p><FormattedMessage id="and" defaultMessage="dhe" /></p>
                            </div>

                            {item.submenu.map((submenu, key) => {
                                return (
                                    <Link key={key} to={submenu.url}>
                                        {submenu.pageName}
                                    </Link>
                                );
                            })}
                        </div>

                    </div>
                )
                    : (
                        <Fragment>
                            {
                                item.otherSource === false ? (
                                    <Link className="slider-box-link" to={item.url}>
                                        <img src={image} alt="" />
                                        {item.pageName}
                                    </Link>
                                ) : (
                                    <a className="slider-box-link" target={item.targeti} href={item.url}>
                                        <img src={image} alt="" />
                                        {item.pageName}
                                    </a>
                                )
                            }
                        </Fragment>
                    )}
            </li>
        );
    });

    return (
        <Fragment>
            <div className="e-service">
                {location !== 'slider' && (
                    <div className="news-ballina">
                        <div className="news-title">
                            <h3>{menuname}</h3>
                        </div>
                    </div>
                )}
                <div className={`block-slideshow__slide-content box-${location}`}>
                    <ul className="slider-boxes">
                        {linksList}
                    </ul>
                </div>
            </div>
        </Fragment>
    );
}

BlockSlideBox.propTypes = {
    location: PropTypes.oneOf(['home', 'slider', 'aboutus']),
    // locale: PropTypes.string,
};

BlockSlideBox.defaultProps = {
    location: 'home',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockSlideBox);
