import React from 'react';

import PropTypes from 'prop-types';

function PageTitle(props) {
    let { header } = props;

    if (header) {
        header = (
            <div className="page-header__title">
                <h1>{header}</h1>
            </div>
        );
    }

    return (
        <div className="page-header">
            <div className="page-header__container container">
                {header}
            </div>
        </div>
    );
}

PageTitle.propTypes = {
    header: PropTypes.node,
};

export default PageTitle;
