// react
import React from 'react';

// third-party

function PostCardLogo(props) {
    const { post } = props;
    const path = process.env.REACT_APP_FILESPATH;
    const image = `${path}${post.media[0]?.mediaEmri}${post.media[0]?.mediaEx}`;
    return (
        <div className="logo-box">
            <a href={post.url} target={post.targeti}>
                <img src={post.media[0]?.otherSource === null ? image : post.media[0]?.otherSource} alt="" />
            </a>
        </div>
    );
}

export default PostCardLogo;
