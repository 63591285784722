// react
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
// import { withRouter } from 'react-router-dom';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Cross20Svg } from '../../svg';

function Search(props) {
    const {
        context,
        className,
        onClose,
        locale,
    } = props;
    const [text, setText] = useState([]);
    const history = useHistory();
    const handleChange = (e) => {
        e.preventDefault();
        setText({
            ...text,
            [e.target.name]: e.target.value,
        });
    }
    const rootClasses = classNames(`search search--location--${context}`, className);

    const close = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    const handleKeyDown = (event) => {
        // Escape.
        if (event.which === 27) {
            close();
        }
        if (event.keyCode === 13) {
            if (text.search !== '') {
                history.push(`/Search/${text.search}`);
            }
        }
    };

    const closeButton = context !== 'mobile-header' ? '' : (
        <button className="search__button search__button--type--close" type="button" onClick={close}>
            <Cross20Svg />
        </button>
    );
    let searchInput;
    if (locale === 'sq') {
        searchInput = (
            <input
                className="search__input"
                name="search"
                placeholder="Kërko..."
                onKeyDown={handleKeyDown}
                aria-label="Site search"
                type="text"
                autoComplete="off"
                onChange={handleChange}
            />
        )
    } else if (locale === 'en') {
        searchInput = (
            <input
                className="search__input"
                name="search"
                placeholder="Search..."
                onKeyDown={handleKeyDown}
                aria-label="Site search"
                type="text"
                autoComplete="off"
                onChange={handleChange}
            />
        )
    } else if (locale === 'sr') {
        searchInput = (
            <input
                className="search__input"
                name="search"
                placeholder="Pretraga..."
                onKeyDown={handleKeyDown}
                aria-label="Site search"
                type="text"
                autoComplete="off"
                onChange={handleChange}
            />
        )
    }

    return (
        <div className={rootClasses}>
            <div className="search__body">
                <div className="search__form">
                    {searchInput}

                    <Link className="search__icone" to={{ pathname: `/Search/${text.search}` }}>
                        <button className="search__button search__button--type--submit" type="submit">
                            {/* <Search20Svg /> */}
                            <FormattedMessage id="Search.name" defaultMessage="Kërko" />
                        </button>
                    </Link>

                    {closeButton}
                    <div className="search__border" />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(Search);
