import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import RECAPTCHA from 'react-google-recaptcha';
// import Collapse from '../shared/Collapse';
import { getData, postData } from '../../services/fetch-service';
import BlockContact from '../blocks/BlockContact';

function contact(props) {
    const url = window.location.pathname.split('/').pop();
    const history = useHistory();
    const params = useParams();
    const { locale } = props;
    const captchaRef = useRef(null);
    // const [contactData, setContactData] = useState([]);
    const [pika, setPikat] = useState('');
    const [ddlcontact, setDdlContact] = useState([]);
    const [error, setError] = useState(null);
    const [errorMessage, seterrorMessage] = useState('');
    const [contact, setContact] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
        contactId: 0,
    });
    useEffect(() => {
        setContact({
            name: '',
            email: '',
            subject: '',
            message: '',
            contactId: 0,
        });
        getData(`Contact/GetContacts?PageID=${params.id}&Gjuha=${locale}`).then((response) => {
            // setContactData(response.data);
            setPikat(response.data[0]);
        }).catch(() => {
            history.push('/404');
        })

        getData(`Contact/GetContactsList?PageID=${params.id}&Gjuha=${locale}`).then((response) => {
            setDdlContact(response.data);
        });
    }, [url]);
    const clearFields = () => {
        setContact({
            name: '',
            email: '',
            subject: '',
            message: '',
            contactId: 0,
        });
    }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    let depatmentSelect;
    if (locale === 'sq') {
        depatmentSelect = (
            <option value="0">Zgjedh</option>
        )
    } else if (locale === 'en') {
        depatmentSelect = (
            <option value="0">Select</option>
        )
    } else if (locale === 'sr') {
        depatmentSelect = (
            <option value="0">Izaberite</option>
        )
    }

    const sendData = () => {
        const token = captchaRef.current.getValue();
        let error = false;
        seterrorMessage('Të gjitha fushat janë obligative');
        if (token === null || token.trim().length === 0) {
            error = true;
        } else if (contact.name.trim().length === 0) {
            error = true;
        } else if (contact.subject.trim().length === 0) {
            error = true;
        } else if (contact.message.trim().length === 0) {
            error = true;
        } else if (contact.contactId === 0 || contact.contactId === '0') {
            error = true;
        } else if (!isValidEmail(contact.email)) {
            error = true;
            seterrorMessage('Formati i Email-it nuk është në rregull');
        }
        if (error === false) {
            postData(`Contact/SendMail?token=${token}`, contact)
                .then((response) => {
                    if (response.data === false) {
                        toast.error('Gabim gjatë dergimit te mesazhit', { theme: 'colored' });
                        captchaRef.current.reset();
                    } else {
                        toast.success('Mesazhi u dërgua me sukses', { theme: 'colored' });
                        clearFields();
                        captchaRef.current.reset();
                    }
                });
            setError(null);
        } else {
            setError(true);
            // toast.error(message, { theme: 'colored' });
        }
    }

    const handleChange = (e) => {
        e.preventDefault();
        setContact({
            ...contact,
            [e.target.name]: e.target.value,
        });
    }

    let inputClass = classNames('')

    if (error === true) {
        inputClass = classNames('form-control');
    } else {
        inputClass = classNames('form-control');
    }

    const cordinates = `https://maps.google.com/maps?q=${pika.gps}&hl=es;z=14&amp;output=embed`;
    return (
        <React.Fragment>
            <div className="block contact-block">
                <div>
                    <div className="mb-0 contact-us">
                        <div className="contact-body">
                            <div className="contact-us__container">
                                <BlockContact />
                                <div className="contact-box-grid">
                                    <div className="contact-input-grid">
                                        <div className="">
                                            <div className="contact-box input-c-box">
                                                <h4 className="contact-us__header card-title"><FormattedMessage id="Contact.FormName" defaultMessage="Mesazhi" /></h4>
                                                <form>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-12">
                                                            <label htmlFor="name">
                                                                <FormattedMessage id="contact.label" defaultMessage="Contact" />
                                                            </label>
                                                            <select className={inputClass} name="contactId" onChange={handleChange}>
                                                                {depatmentSelect}
                                                                {ddlcontact.map((dep, index) => <option key={index} value={dep.kontaktiId}>{dep.kontaktiPershkrimi}</option>)}
                                                            </select>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="name">
                                                                <FormattedMessage id="Contact.FormEmri" defaultMessage="Emri" />
                                                            </label>
                                                            <input type="text" id="name" name="name" value={contact.name} required onChange={handleChange} className={inputClass} />
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="email">
                                                                <FormattedMessage id="Contact.FormEmail" defaultMessage="Email" />
                                                            </label>
                                                            <input
                                                                type="email"
                                                                id="email"
                                                                name="email"
                                                                className={inputClass}
                                                                value={contact.email}
                                                                required
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="subject">
                                                            <FormattedMessage id="Contact.FormSubject" defaultMessage="Subjekti" />
                                                        </label>
                                                        <input type="text" id="subject" name="subject" value={contact.subject} required onChange={handleChange} className={inputClass} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="message">
                                                            <FormattedMessage id="Contact.FormMessage" defaultMessage="Mesazhi" />
                                                        </label>
                                                        <textarea id="message" name="message" value={contact.message} required onChange={handleChange} className={inputClass} rows="4" />
                                                    </div>
                                                    <div className="captcha-box">
                                                        {error && <h5 style={{ color: 'red' }}>{errorMessage}</h5>}
                                                        <RECAPTCHA
                                                            className="form-group captcha"
                                                            sitekey={process.env.REACT_APP_SITE_KEY}
                                                            ref={captchaRef}
                                                        />
                                                    </div>
                                                    <button type="button" onClick={sendData} className="btn btn-primary">
                                                        <FormattedMessage id="Contact.SendButton" defaultMessage="Dërgo" />
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                                            className="contact-us__map"
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    `<iframe
                                                        title="Google Map"                                
                                                        src="${cordinates}"
                                                        frameBorder="0"
                                                        scrolling="no"
                                                        marginHeight="0"
                                                        marginWidth="0"
                                                    />`,
                                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(contact);
