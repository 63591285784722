// react
import React, { useState, useEffect } from 'react';

// third-party
import PropTypes from 'prop-types';
// import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
// import Fade from 'react-reveal/Fade';
// application
import EventList from './EventList';
import { getData } from '../../../services/fetch-service';
import Pagination from '../../shared/Pagination';
import BasicPage from '../page/BasicPage';
import PageTitle from '../../shared/PageTitle';
// import SkletonEvents from '../../Skeletons/Skeleton-events';
// import SkletonEventsTitle from '../../Skeletons/Skeleton-events-title';

function NewsCategory(props) {
    const url = window.location.pathname.split('/').pop();
    const { layout } = props;
    const { locale } = props;
    const params = useParams();
    const [post, setPost] = useState([]);
    const [page, setPage] = useState([]);
    const [totalPage, setTotalPage] = useState();
    const [CurrentPage, setCurrentPage] = useState();

    const handleChange = (skip = 0) => {
        getData(`News/GetNewsWithCategoryFilter?PageId=${params.id}&Gjuha=${locale}&skip=${skip}&take=5&TitulliLength=70&PermbajtjaLength=195`).then((response) => {
            setPage(response.data.page[0]);
            setPost(response.data.lajmetLista);
            setTotalPage(response.data.totalPages);
        });
    }
    useEffect(() => {
        handleChange();
    }, [url]);

    const handlePageChange = (CurrentPage) => {
        setCurrentPage(CurrentPage);
        const skip = (CurrentPage * 5) - 5;
        handleChange(skip);
    };

    const postsList = post.map((post, index) => {
        const postLayout = {
            classic: 'grid-lg',
            grid: 'grid-nl',
            list: 'list-nl',
        }[layout];

        return (
            <div key={index} className="posts-list__item news-list">
                <EventList post={post} layout={postLayout} />
            </div>
        );
    });

    return (
        <React.Fragment>
            {/* {
                loader === true
                    ? (
                        <SkletonEventsTitle />
                    )
                    : (
                        <Fade big> */}
            <div className="event-header">
                <PageTitle header={page.pageName} />
                <BasicPage />
            </div>
            {/* </Fade>
                    )
            } */}

            <div className="block-publications">
                <div className="posts-view">
                    <div className={`posts-view__list posts-list posts-list--layout--${layout}`}>
                        <div className="posts-list__body">
                            {postsList}
                        </div>
                    </div>
                    {
                        post.length > 0 ? (
                            <div className="posts-view__pagination">
                                <Pagination
                                    current={CurrentPage}
                                    siblings={2}
                                    total={totalPage}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        ) : ('')
                    }
                </div>
            </div>
        </React.Fragment>
    );
}

NewsCategory.propTypes = {
    layout: PropTypes.oneOf(['classic', 'grid', 'list']),
};

NewsCategory.defaultProps = {
    layout: 'list',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(NewsCategory);
