import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { getData } from '../../services/fetch-service';
import Collapse from '../shared/Collapse';

function FaqAnswers(props) {
    const { locale, id } = props;
    const [faqs, setFaqs] = useState([]);
    const params = useParams();
    useEffect(() => {
        getData(`FAQ/GetFAQ?PageId=${params.id}&&Gjuha=${locale}&GroupId=${id}`).then((response) => {
            setFaqs(response.data[0].faqs);
        })
    }, [id]);

    return (
        <Fragment>
            {
                faqs.length > 0 && (
                    <div className="faq-collapse">
                        <h4 className="faq-answer-title"><FormattedMessage id="Pyetjet" defaultMessage="Pyetjet" /></h4>
                        <div className="faq-answers">
                            <div className="answer-boxes">
                                {faqs.map((faq, index) => {
                                    const renderCategory = (
                                        {
                                            toggle, setItemRef, setContentRef,
                                        },
                                    ) => {
                                        let expander;

                                        if (faqs.length > 0) {
                                            expander = <button className="widget-categories__expander" type="button" aria-label="Expand" onClick={toggle} />
                                        }

                                        function handleKeyDown(e) {
                                            e.preventDefault();
                                        }

                                        return (
                                            <div key={index} className="" ref={setItemRef}>
                                                <div className="collapse-box">
                                                    <div role="button" onKeyDown={handleKeyDown} tabIndex={0} onClick={toggle} className="title-collapse">
                                                        <h4 className="card-title">{faq.faqpytja}</h4>
                                                        <div role="button" onKeyDown={handleKeyDown} tabIndex={0} onClick={toggle} className="expander">
                                                            {expander}
                                                        </div>

                                                    </div>
                                                    <div
                                                        className="collapse-content"
                                                        ref={setContentRef}
                                                        dangerouslySetInnerHTML={{ __html: faq.faqpergjigjja }}
                                                    >
                                                        {/* <p>{faq.faqpergjigjja}</p> */}

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    };
                                    return <Collapse key={index} toggleClass="menu__item--open" render={renderCategory} />;
                                })}
                            </div>
                        </div>
                    </div>
                )
            }
        </Fragment>
    );
}

FaqAnswers.propTypes = {
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(FaqAnswers);
