// react
import React, { Component } from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import Slider from 'react-slick';
// import { FormattedMessage } from 'react-intl';
// import { Link } from 'react-router-dom';

// application
// import BlockHeader from '../shared/BlockHeader';
import PostCardLogo from '../shared/PostCardLogo';
import StroykaSlick from '../shared/StroykaSlick';
import { getData } from '../../services/fetch-service';

const slickSettings = {
    'grid-nl': {
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 701,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    'grid-nl-4': {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    'list-sm': {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 400,
        speed: 3000,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    },
};

class BlockLogo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            post: [],
        };
    }

    componentDidMount() {
        const { locale } = this.props;
        getData(`Home/GetMenus?Gjuha=${locale}&LocationMenuID=13&ParentMenuID=0`).then((response) => {
            this.setState({
                post: response.data,
            });
        });
    }

    handleNextClick = () => {
        if (this.slickRef) {
            this.slickRef.slickNext();
        }
    };

    handlePrevClick = () => {
        if (this.slickRef) {
            this.slickRef.slickPrev();
        }
    };

    setSlickRef = (ref) => {
        this.slickRef = ref;
    };

    render() {
        const { layout } = this.props;
        const { post } = this.state;
        const slidess = post.map((post) => <PostCardLogo key={post.postimiId} post={post} />);

        return (
            <div className={`slider-ballina--${layout} logos-section`} data-layout={layout}>
                <div className="container">
                    <div className="block-posts__slider slider-logos">
                        <StroykaSlick
                            ref={this.setSlickRef}
                            {...slickSettings[layout]}
                        >
                            {slidess}
                        </StroykaSlick>
                    </div>
                </div>
            </div>
        );
    }
}

BlockLogo.propTypes = {
    // title: PropTypes.string.isRequired,
    layout: PropTypes.oneOf(['list-sm', 'grid-nl', 'grid-nl-4']),
    posts: PropTypes.array,
    locale: PropTypes.string,
};

BlockLogo.defaultProps = {
    layout: 'list-sm',
    posts: [],
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockLogo);
