// react
import { React, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
// third-party
// import Moment from 'moment';
// import classNames from 'classnames';
// import Moment from 'moment';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getData } from '../../../services/fetch-service';
// import Pagination from '../../shared/Pagination';
import PostLoader from '../../blocks/PostLoader';

function PersonelCardRrethArkep(props) {
    const { layout } = props;
    const params = useParams();
    const { locale } = props;
    const url = window.location.pathname.split('/').pop();
    const [personeli, setPersoneli] = useState([]);
    const [loader, setLoader] = useState(false);

    const handleChange = (skip = 0) => {
        setLoader(true);
        getData(`Personel/GetPersonels?PageID=${params.id}&Gjuha=${locale}&skip=${skip}&take=9`).then((response) => {
            setPersoneli(response.data.result);
            setLoader(false);
        });
        setLoader(false);
    }

    useEffect(() => {
        handleChange();
    }, [url]);

    const personeliList = personeli.map((personeli, key) => {
        return (
            <Link to={personeli.url} key={key}>
                <div>
                    <div className="personel-card-content">
                        <div className="text-section">
                            <div className="personel-card-info-arkep">
                                {(personeli.personeliEmri.length === 0 || personeli.personeliMbiemri.length === 0) ? (
                                    ('')
                                ) : (
                                    <div className="personel-info-name">
                                        {/* <p className="personel-info-type">
                                            <FormattedMessage id="Personel.card.name" defaultMessage="Example Text" />
                                        </p> */}
                                        <p className="personel-info-text">
                                            {personeli.personeliEmri}
                                            &nbsp;
                                            {personeli.personeliMbiemri}
                                        </p>
                                    </div>
                                )}
                                {(personeli.personeliPozita.length === 0) ? (
                                    ('')
                                ) : (
                                    <div className="personel-info-position">
                                        <p className="personel-info-text">
                                            {personeli.personeliPozita}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        );
    });

    return (
        <div>
            <div className="aboutus-bordi">
                <div className="row">
                    <div className="block personel-block">
                        <div className="posts-view">
                            <div className={`posts-view__list-arkep posts-list posts-list--layout--${layout}`}>
                                <div className="arkep-keshilli">
                                        <h6><FormattedMessage id="Keshilli-Drejtues" defaultMessage="KËSHILLI DREJTUES" /></h6>
                                        <h1><FormattedMessage id="Bordi-Arkep" defaultMessage="Bordi ARKEP" /></h1>
                                </div>
                                <div className="personel-details-data-aboutus">
                                    {
                                        loader === true ? <PostLoader /> : ''
                                    }
                                    {personeliList}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

PersonelCardRrethArkep.propTypes = {
    /**
     * post layout
     * one of ['classic', 'full'] (default: 'classic')
     */
    locale: PropTypes.string,
    layout: PropTypes.oneOf(['grid', 'grid-nl', 'grid-nl-4', 'grid-lg', 'list-nl', 'list-sm']),
};
PersonelCardRrethArkep.defaultProps = {
    layout: 'grid',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(PersonelCardRrethArkep);
