// react
import React, { useState, useEffect, Fragment } from 'react';
import Moment from 'moment';

// third-party
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

// application
import { getData } from '../../services/fetch-service';

function HomeDocsAndLinks(props) {
    const { layout } = props;
    const { locale } = props;
    const [doc, setDoc] = useState([]);
    const [docraportet, SetDocRaportet] = useState([]);
    // const [eventet, setEventet] = useState([]);
    // const [DocName, setDocName] = useState([]);
    // const [ngjarjetName, setNgjarjetName] = useState([]);

    useEffect(() => {
        getData(`News/GetNewsWithCategoryFilter?PageId=26&Gjuha=${locale}&skip=0&take=5&TitulliLength=100&PermbajtjaLength=100`).then((response) => {
            setDoc(response.data.lajmetLista);
        });
        getData(`Page/GetPageMedia?PageID=105&Gjuha=${locale}&skip=0&take=5&TitulliLength=100&PermbajtjaLength=100`).then((response) => {
            SetDocRaportet(response.data.mediaLista);
        });
    }, [locale]);

    const docLista = doc.map((item, index) => {
        // const path = process.env.REACT_APP_FILESPATH;
        // const iconpath = process.env.REACT_APP_ICONSPATH;
        // const link = item.media.otherSource === null ? `${path}${item.media.mediaEmri}${item.media.mediaEx}` : item.media.otherSource;
        // const iconslink = `${iconpath}${item.fileIco.mediaExPath}`;
        return (
            <div key={index} className="file_manager">
                <div className="card-docs">
                    <div className="item-docs home-item-docs">
                        <Link to={item.url} className="icon-docs">
                            <img src="/desk/inc/ico/link.png" alt="" width="40px" />
                        </Link>
                            <div className="file-name">
                                <Link to={item.url} rel="noreferrer">{item.postimiTitulli}</Link>
                                <div className="file-date file-date-docs">
                                    {Moment(item.postimiDataFillimit).format('DD/MM/YYYY')}
                                </div>
                            </div>
                    </div>
                </div>

            </div>
        );
    });

    const docraportetlista = docraportet.map((item, index) => {
        const path = process.env.REACT_APP_FILESPATH;
        const iconpath = process.env.REACT_APP_ICONSPATH;
        const link = item.media.otherSource === null ? `${path}${item.media.mediaEmri}${item.media.mediaEx}` : item.media.otherSource;
        const iconslink = `${iconpath}${item.fileIco.mediaExPath}`;
        return (
            <div key={index} className="file_manager">
                <div className="card-docs">
                    <div className="item-docs home-item-docs">
                        <Link to={item.url} className="icon-docs">
                            <img src={iconslink} alt="" width="40px" />
                        </Link>
                        {item.media.otherSource === null ? (
                        <div className="file-name file-home-name">
                            <a href={link} target="_blank" download={item.pageMedia.pershkrimi.replaceAll('.', '')} rel="noreferrer">{item.pageMedia.pershkrimi}</a>
                            <div className="file-date file-date-docs">
                                {Moment(item.pageMedia.dataFillimit).format('DD/MM/YYYY')}
                            </div>
                        </div>
                        ) : (
                            <div className="file-name file-home-name">
                                <a href={link} target="_blank" rel="noreferrer">{item.pageMedia.pershkrimi}</a>
                                <div className="file-date file-date-docs">
                                    {Moment(item.pageMedia.dataFillimit).format('DD/MM/YYYY')}
                                </div>
                            </div>
                            )}
                    </div>
                </div>

            </div>
        );
    });

    return (
        <Fragment>
            {(doc.length > 0) ? (
                <div className="docs-block">
                    <div className={`block-docs-links block--layout--${layout}`}>
                        <div className="row row-50 docs-flex">
                            {doc.length > 0 ? (
                                <div className="col-md-12 col-lg-12 col-xl-6 event-box docsBallin">
                                    <div className="event-first-title">
                                        <h2><FormattedMessage id="Vendimet" defaultMessage="Vendimet" /></h2>
                                        <span />
                                        <Link className="mobile-hide" to="/NewsEvent/26"><FormattedMessage id="ShikoTeGjitha" defaultMessage="Shiko të Gjitha" /></Link>
                                    </div>
                                    <div className="dockList">
                                        {docLista}
                                    </div>
                                    <Link className="mobile-showAll" to="\"><FormattedMessage id="ShikoTeGjitha" defaultMessage="Shiko të Gjitha" /></Link>
                                </div>
                            ) : ('')}
                            {doc.length > 0 ? (
                                <div className="col-md-12 col-lg-12 col-xl-6 event-box docsBallin">
                                    <div className="event-first-title">
                                        <h2><FormattedMessage id="Raportet" defaultMessage="Raportet Tremujore" /></h2>
                                        <span />
                                        <Link className="mobile-hide" to="/Page/105"><FormattedMessage id="ShikoTeGjitha" defaultMessage="Shiko të Gjitha" /></Link>
                                    </div>
                                    <div className="dockList">
                                        {docraportetlista}
                                    </div>
                                    <Link className="mobile-showAll" to="\"><FormattedMessage id="ShikoTeGjitha" defaultMessage="Shiko të Gjitha" /></Link>
                                </div>
                            ) : ('')}
                        </div>
                    </div>
                </div>
            ) : ('')}

        </Fragment>
    );
}

HomeDocsAndLinks.propTypes = {
    layout: PropTypes.oneOf(['classic', 'boxed', 'sidebar', 'fullpage-satistics']),
};

HomeDocsAndLinks.defaultProps = {
    layout: 'classic',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(HomeDocsAndLinks);
