// react
import React, { useState, useEffect } from 'react';
// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { getData } from '../../services/fetch-service';
// data stubs
// import theme from '../../data/theme';

function SocialLinks(props) {
    const { shape, className, locale } = props;
    const [socialLinks, setSocialLinks] = useState([]);
    useEffect(() => {
        getData(`Home/GetSocialNetworks?Gjuha=${locale}&Layout=default`).then((response) => {
            setSocialLinks(response.data);
        })
    }, []);

    const classes = classNames(className, 'social-links', {
        'social-links--shape--circle': shape === 'circle',
        'social-links--shape--rounded': shape === 'rounded',
    });
    // const path = process.env.REACT_APP_FILESPATH;
    const items = socialLinks.map((item, key) => (
        <li key={key} className="social-links__item">
            {item.html !== '' && item.html !== null ? (
                <a
                    className={`social-links__link social-links__link--type--${item.emertimi}`}
                    href={item.linku}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i className={item.html} />
                </a>
            ) : (
                <a
                    className={`social-links__link social-links__link--type--${item.emertimi}`}
                    href={item.linku}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={`${item.imgPath}`} alt="" width="34px" height="34px" />
                </a>
            )}
        </li>
    ));

    return (
        <div className={classes}>
            <ul className="social-links__list">
                {items}
            </ul>
        </div>
    );
}

SocialLinks.propTypes = {
    /**
     * rating value
     */
    shape: PropTypes.oneOf(['circle', 'rounded']),
    className: PropTypes.string,
};
SocialLinks.defaultProps = {
    shape: null,
};

export default SocialLinks;
