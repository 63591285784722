// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';

// application
import AppLink from '../shared/AppLink';
import Collapse from '../shared/Collapse';
import { ArrowRoundedDown12x7Svg } from '../../svg';

function MobileLinks(props) {
    const { links, level, onItemClick } = props;

    const handleItemClick = (item) => {
        if (onItemClick) {
            onItemClick(item);
        }
    };

    const linksList = links.map((link, index) => {
        const item = (
            <Collapse
                toggleClass="mobile-links__item--open"
                render={({ toggle, setItemRef, setContentRef }) => {
                    let arrow;
                    let subLinks;
                    let linkOrButton;

                    if (link.submenu && link.submenu.length > 0) {
                        arrow = (
                            <button className="mobile-links__item-toggle" type="button" onClick={toggle}>
                                <ArrowRoundedDown12x7Svg className="mobile-links__item-arrow" />
                            </button>
                        );

                        subLinks = (
                            <div className="mobile-links__item-sub-links" ref={setContentRef}>
                                <MobileLinks
                                    links={link.submenu}
                                    level={level + 1}
                                    onItemClick={onItemClick}
                                />
                            </div>
                        );
                    }

                    if (link.hasChild === false && link.otherSource === false) {
                        linkOrButton = (
                            <AppLink
                                to={link.url}
                                className="mobile-links__item-link"
                                onClick={() => handleItemClick(link)}
                            >
                                {link.pageName}
                            </AppLink>
                        );
                    } else if (link.hasChild === false && link.otherSource === true) {
                        linkOrButton = (
                            <a
                                href={link.url}
                                target={link.targeti}
                                className="mobile-links__item-link"
                                onClick={() => handleItemClick(link)}
                            >
                                {link.pageName}
                            </a>
                        );
                    } else if (link.hasChild === true && link.otherSource === false) {
                        linkOrButton = (
                            <AppLink
                                to={link.url}
                                className="mobile-links__item-link"
                                onClick={() => handleItemClick(link)}
                            >
                                {link.pageName}
                            </AppLink>
                        );
                    } else if (link.hasChild === true && link.otherSource === true) {
                        linkOrButton = (
                            <a
                                href={link.url}
                                target={link.targeti}
                                className="mobile-links__item-link"
                                onClick={() => handleItemClick(link)}
                            >
                                {link.pageName}
                            </a>
                        );
                    } else {
                        linkOrButton = (
                            <AppLink
                                to={link.url}
                                className="mobile-links__item-link"
                                onClick={() => handleItemClick(link)}
                            >
                                {link.pageName}
                            </AppLink>
                        );
                    }

                    return (
                        <div className="mobile-links__item" ref={setItemRef}>
                            <div className="mobile-links__item-title">
                                {linkOrButton}
                                {arrow}
                            </div>
                            {subLinks}
                        </div>
                    );
                }}
            />
        );

        return <li key={index}>{item}</li>;
    });

    return (
        <ul className={`mobile-links mobile-links--level--${level}`}>
            {linksList}
        </ul>
    );
}

MobileLinks.propTypes = {
    links: PropTypes.array,
    level: PropTypes.number,
    onItemClick: PropTypes.func,
};

MobileLinks.defaultProps = {
    links: [],
    level: 0,
};

export default MobileLinks;
