// react
import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// application
import { connect } from 'react-redux';
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import FooterLinksTwo from './FooterLinksTwo';
// import FooterNewsletter from './FooterNewsletter';
import ToTop from './ToTop';
import FooterVegezat from './FooterVegezat';
import FooterBottomLinks from './FooterBottomLinks';

function Footer(props) {
    const getCurrentYear = () => {
        return new Date().getFullYear();
    };
    const { locale } = props;

    let logo;

    if (locale === 'sq') {
        logo = (
            <div className="site-footer__logo">
                <Link to="/"><img src="/images/logot/ARKEP_FOOTER_SHQ.png" alt="" /></Link>
            </div>
        );
    } else if (locale === 'en') {
        logo = (
            <div className="site-footer__logo">
                <Link to="/"><img src="/images/logot/ARKEP_FOOTER_EN.png" alt="" /></Link>
            </div>
        );
    } else if (locale === 'sr') {
        logo = (
            <div className="site-footer__logo">
                <Link to="/"><img src="/images/logot/ARKEP_FOOTER_SR.png" alt="" /></Link>
            </div>
        );
    }

    return (
        <div className="site-footer">
            <div className="footer">
                <div className="site-footer__widgets">
                    <div className="row footer-row">
                        <div className="col-xs-8 col-sm-8 col-md-8 col-lg-4 col-xl-3 footer-img-box">
                            <div className="logo-footer-grid">
                                {logo}
                                <div className="">
                                    <div className="footer-links-box">
                                        <FooterContacts />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="links-ft">
                            <div className="footer-links-box">
                                <FooterLinks />
                            </div>
                        </div>
                        <div className="links-ft">
                            <div className="footer-links-box">
                                <FooterLinksTwo />
                            </div>
                        </div>

                        <div className="links-ft">
                            <div className="footer-links-box">
                                <FooterVegezat />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <FooterBottomLinks />
            <div className="site-footer__bottom">

                <div className="container">
                    <div className="footer__bottom">
                        <div className="site-footer__copyright">
                            Copyright ©
                            {' '}
                            {getCurrentYear()}
                            {'. '}
                            <FormattedMessage id="Copyrights" defaultMessage="Copyrights" />
                            {' '}
                        </div>
                    </div>
                </div>
            </div>
            <div className="sticky-button">
                <ToTop />
                {/* <Chatbox /> */}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(Footer);
