import React, { useEffect, Fragment } from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import NavLinks from './NavLinks2';

function NavPanel(props) {
    const { layout, locale } = props;
    const [scrolled, setScrolled] = React.useState(false);
    let links;

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 180) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    });
    const navbarClasses = ['nav-panel'];

    const logoClasses = ['site-header__logo'];

    if (scrolled) {
        navbarClasses.push('scrolled');

        logoClasses.push('scroll');
    }

    let logo;
    if (locale === 'sq') {
        logo = (
            <div className="site-header__logo-bottom">
                <Link to="/"><img src="/images/logot/Arkep_SHQ.png" alt="" /></Link>
            </div>
        )
    } else if (locale === 'en') {
        logo = (
            <div className="site-header__logo-bottom">
                <Link to="/"><img src="/images/logot/Arkep_EN.png" alt="" /></Link>
            </div>
        )
    } else if (locale === 'sr') {
        logo = (
            <div className="site-header__logo-bottom">
                <Link to="/"><img src="/images/logot/Arkep_SR.png" alt="" /></Link>
            </div>
        )
    }

    if (layout === 'default') {
        links = (
            <Fragment>
                <div className={navbarClasses.join('')}>
                    <div className="nav-panel">
                        <div className="nav-panel__row">
                            {logo}
                            <div className="nav-panel__nav-links nav-links">
                                <NavLinks layout="default" />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
    return (
        <Fragment>
            {links}
        </Fragment>

    );
}

NavPanel.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(['default', 'about']),
};

NavPanel.defaultProps = {
    layout: 'default',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NavPanel);
