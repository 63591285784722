// react
import React, { useMemo } from 'react';

// third-party
// import { Helmet } from 'react-helmet-async';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import BlockPosts from '../blocks/BlockPosts';
import BlockSlideShow from '../blocks/BlockSlideShow';
import BlockSlideBox from '../blocks/BlockSlideBox';
import HomeDocsAndLinks from './HomeDocsAndLinks';
import SecondNews from '../blocks/SecondNews';
import BlockPhotoMenu from '../blocks/BlockPhotoMenu';
import BlockLogo from '../blocks/BlockLogo';

function HomePageOne() {
    return (
        <React.Fragment>
            <div className="row rreshtiii">
                <div className="col-md-12 kolonaa slider-row">
                    <div className="col-lg-8 slider-section">
                        {useMemo(() => <BlockSlideShow />, [])}
                    </div>
                    <div className="col-lg-4 slider-section">
                        {useMemo(() => <BlockSlideBox location="slider" />, [])}
                    </div>
                </div>
            </div>

            <div className="container-full">
                {useMemo(() => <BlockSlideBox location="home" />, [])}
                <div className="news-ballina">
                    <div className="news-title">
                        <Link className="event-btn" to="/News"><h3><FormattedMessage id="news-home-title" defaultMessage="Lajmet & Ngjarje" /></h3></Link>
                    </div>
                    <div className="news-home">
                        {useMemo(() => <BlockPosts title="Latest News" layout="grid-nl" />, [])}

                        {useMemo(() => <SecondNews title="Latest News" layout="grid-nl" />, [])}
                    </div>
                </div>
                {useMemo(() => <BlockPhotoMenu />, [])}

                {useMemo(() => <HomeDocsAndLinks />, [])}

                {useMemo(() => <BlockLogo />, [])}

            </div>
        </React.Fragment>
    );
}

export default HomePageOne;
