// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';

// application
import { ArrowRoundedLeft7x11Svg, ArrowRoundedRight7x11Svg } from '../../svg';

function BlockHeader(props) {
    const {
        onPrev,
        onNext,
    } = props;
    let { arrows } = props;

    if (arrows) {
        arrows = (
            <div className="block-header__arrows-list">
                <button className="block-header__arrow block-header__arrow--left" type="button" onClick={onPrev}>
                    <ArrowRoundedLeft7x11Svg />
                </button>
                <button className="block-header__arrow block-header__arrow--right" type="button" onClick={onNext}>
                    <ArrowRoundedRight7x11Svg />
                </button>
            </div>
        );
    }

    return (
        <div className="block-header">
            <div className="block-arrows">
                {arrows}
            </div>
        </div>
    );
}

BlockHeader.propTypes = {
    arrows: PropTypes.bool,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
};

export default BlockHeader;
