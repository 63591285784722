// react
import React, { useState, useEffect } from 'react';

// third-party
import PropTypes from 'prop-types';
// import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
// application
import { FormattedMessage } from 'react-intl';
import NewsCard from './NewsCard';
import BlogSidebar from '../sidebar/BlogSidebar';
import { getData } from '../../../services/fetch-service';
import Pagination from '../../shared/Pagination';
import getMenuLocation from '../page/getMenuLocation';
import PostLoader from '../../blocks/PostLoader';
import PageTitle from '../../shared/PageTitle';

function NewsCategory(props) {
    const url = window.location.pathname.split('/').pop();
    const { layout, sidebarPosition } = props;
    const { locale } = props;
    const params = useParams();
    const [menuLocationId, setmenuLocationId] = useState();
    const [post, setPost] = useState([]);
    // const [page, setPage] = useState([]);
    const [totalPage, setTotalPage] = useState();
    const [CurrentPage, setCurrentPage] = useState();
    const [loader, setLoader] = useState(false);

    const handleChange = (skip = 0) => {
        setLoader(true);
        getData(`News/GetNewsWithCategoryFilter?PageId=${params.id}&Gjuha=${locale}&skip=${skip}&take=9&TitulliLength=80&PermbajtjaLength=160`).then((response) => {
            setPost(response.data.lajmetLista);
            setTotalPage(response.data.totalPages);
            setLoader(false);
            // setPage(response.data.page[0]);
        });
    }
    useEffect(() => {
        getData(`Home/GetMenuPath?PageID=${params.id}&Gjuha=${locale}`).then((response) => {
            setmenuLocationId(response.data[0].menuLocationGroupID);
        });
        handleChange();
    }, [url]);

    const handlePageChange = (CurrentPage) => {
        setCurrentPage(CurrentPage);
        const skip = (CurrentPage * 9) - 9;
        handleChange(skip);
    };

    let sidebarStart;
    let sidebarEnd;
    const template = getMenuLocation(menuLocationId);
    const sidebar = <BlogSidebar layout={template} position={sidebarPosition} />;

    if (sidebarPosition === 'start') {
        sidebarStart = <div className="col-12 col-lg-3 order-1 order-lg-0">{sidebar}</div>;
    } else if (sidebarPosition === 'end') {
        sidebarEnd = <div className="col-12 col-lg-3">{sidebar}</div>;
    }

    const postsList = post.map((post, index) => {
        // const postLayout = {
        //     classic: 'grid-lg',
        //     grid: 'grid-nl',
        //     list: 'list-nl',
        // }[layout];

        return (
            <NewsCard key={index} post={post} />
        );
    });

    return (
        <React.Fragment>
            <PageTitle header={<FormattedMessage id="Konsultime" defaultMessage="Konsultime" />} />
            <div className="container">
                <div className="row">
                    {sidebarStart}
                    <div className="col-12 col-lg-9 block-news-category ">
                        <div className="block">
                            <div className="posts-view">
                                <div className={`posts-view__list posts-list posts-list--layout--${layout}`}>
                                    <div className="lajme-list__body">
                                        {
                                            loader === true ? <PostLoader /> : ''
                                        }
                                        <div className="lajmet-grid">
                                            {postsList}
                                        </div>

                                    </div>
                                </div>
                                <div className="posts-view__pagination">
                                    {/* <Pagination
                                        current={CurrentPage}
                                        siblings={2}
                                        total={totalPage}
                                        onPageChange={handlePageChange}
                                    /> */}
                                    {
                                        post.length > 0 ? (
                                            <div className="posts-view__pagination">
                                                <Pagination
                                                    current={CurrentPage}
                                                    siblings={2}
                                                    total={totalPage}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        ) : ('')
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {sidebarEnd}
                </div>
            </div>
        </React.Fragment>
    );
}

NewsCategory.propTypes = {
    /**
     * blog layout
     * one of ['classic', 'grid', 'list'] (default: 'classic')
     */
    layout: PropTypes.oneOf(['classic', 'grid', 'list']),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(['start', 'end']),
};

NewsCategory.defaultProps = {
    layout: 'list',
    sidebarPosition: 'end',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(NewsCategory);
