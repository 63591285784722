// react
import { React, useState, useEffect } from 'react';

// third-party
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getData } from '../../services/fetch-service';

function FooterLinks(props) {
    // const { title, items } = props;
    const { locale } = props;
    const [Vegzat, setVegzat] = useState([]);
    useEffect(() => {
        getData(`Home/GetLinks?LinkTypeID=1&Gjuha=${locale}&PageID=1`).then((response) => {
            setVegzat(response.data);
        });
    }, []);

    const VegzatList = Vegzat.map((item, index) => (
        <li key={index} className="footer-links__item list">
            <a href={item.linku} target={item.linkuHape} className="footer-links__link ">
                {item.linkuPershkrimi}
            </a>
        </li>
    ));

    return (
        <div className="site-footer__widget footer-links">
            <ul className="footer-links__list">
                {VegzatList}
            </ul>
        </div>
    );
}

FooterLinks.propTypes = {
    /** widget title */
    // title: PropTypes.node.isRequired,
    /** array of links */
    // items: PropTypes.array,
    locale: PropTypes.string,
};

FooterLinks.defaultProps = {
    // items: [],
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(FooterLinks);
