// react
import React, { Component } from 'react';

// third-party
import PropTypes from 'prop-types';
import {
    BrowserRouter,
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { ScrollContext } from 'react-router-scroll-4';

// application
import languages from '../i18n';
import { localeChange } from '../store/locale';

// pages
import Layout from './Layout';
import HomePageOne from './home/HomePageOne';

// import Page from './cms_components/page/Page';
// import PageDocs from './cms_components/page/PageDocs';
import PageDocsFilter from './cms_components/page/PageDocsFilter';
import News from './cms_components/news/News';
import NewsArchive from './cms_components/news/NewsArchive';
import NewsDetails from './cms_components/news/NewsDetails';
import Faq from './cms_components/Faq';
import Personel from './cms_components/personel/Personel';
import PersonelDetails from './cms_components/personel/PersonelDetails';
import PhotoGalery from './cms_components/fotogalery/FotoGalery';
import PhotoGaleryDetails from './cms_components/fotogalery/FotoGaleryDetails';
import VideoGalery from './cms_components/videoGalery/VideoGalery';
import VideoGaleryDetails from './cms_components/videoGalery/VideoGaleryDetails';
// import Services from './cms_components/Services';
// import Projects from './cms_components/Projects';
import Contact from './cms_components/Contact';
import SitePageNotFound from './site/SitePageNotFound';
import SitePageError from './site/SitePageError';
import Search from './cms_components/search/Search';
import NewsCategory from './cms_components/news/NewsCategory';
// import Thesari from './cms_components/thesari/Thesari';
import RrethArkep from './cms_components/arkep/RrethArkep';
// import Buxheti from './cms_components/buxheti/Buxheti';
// import BuxhetiHome from './cms_components/buxheti/BuxhetiHome';
// import TatimiNeProne from './cms_components/tatimiNeProne/TatimiNeProne';
// import TatimiNeProneHome from './cms_components/tatimiNeProne/TatimiNeProneHome';
// import PartneritetiPublikoPrivat from './cms_components/PartneritetiPublikoPrivat/PartneritetiPublikoPrivat';
// import PartneritetiPublikoPrivatHome from './cms_components/PartneritetiPublikoPrivat/PartneritetiPublikoPrivatHome';
// import Socialet from './cms_components/socialet/Socialet';
// import SocialetHome from './cms_components/socialet/SocialetHome';
// import Puna from './cms_components/puna/Puna';
// import PunaHome from './cms_components/puna/PunaHome';
// import RaportimetFinanciare from './cms_components/raportimeFinanciare/RaportimetFinanciare';
// import RaportimetFinanciareHome from './cms_components/raportimeFinanciare/RaportimetFinanciareHome';
// import Pensionet from './cms_components/pensionet/Pensionet';
// import PensionetHome from './cms_components/pensionet/PensionetHome';
import events from './cms_components/eventet/events';
import FrequencySearch from './blocks/newBlocks/FrequencySearch';
// import events from './cms_components/eventet/events';
// import PunesimetHome from './cms_components/punesimet/PunesimetHome';

class Root extends Component {
    componentDidMount() {
        // preloader
        setTimeout(() => {
            const preloader = document.querySelector('.site-preloader');

            preloader.addEventListener('transitionend', (event) => {
                if (event.propertyName === 'opacity') {
                    preloader.parentNode.removeChild(preloader);
                }
            });
            preloader.classList.add('site-preloader__fade');
        }, 500);

        // this is for demo only, you can delete it
        const { localeChange: changeLocale } = this.props;
        const direction = new URLSearchParams(window.location.search).get('dir');
        if (direction !== null) {
            changeLocale(direction === 'rtl' ? 'ar' : 'en');
        }
    }

    shouldUpdateScroll = (prevRouterProps, { location }) => (
        prevRouterProps && location.pathname !== prevRouterProps.location.pathname
    );

    render() {
        const { locale } = this.props;
        const { messages, direction } = languages[locale];

        return (
            <IntlProvider locale={locale} messages={messages}>
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <HelmetProvider>
                        <Helmet htmlAttributes={{ lang: locale, dir: direction }} />
                        <ScrollContext shouldUpdateScroll={this.shouldUpdateScroll}>
                            <Switch>
                                <Route
                                    exact
                                    path="/"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="default"
                                            hasSidebar="false"
                                            breadcrumbs="false"
                                            homeComponent={HomePageOne}
                                        />
                                    )}
                                />
                                <Route
                                    path="/Page/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/PageDocs/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/PageDocsFilter/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={PageDocsFilter} />
                                    )}
                                />
                                <Route
                                    path="/NewsEvent/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={events} />
                                    )}
                                />
                                <Route
                                    path="/News"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" hasSidebar="false" breadcrumbs="false" homeComponent={News} />
                                    )}
                                />
                                <Route
                                    path="/NewsCategory/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" hasSidebar="false" breadcrumbs="false" homeComponent={NewsCategory} />
                                    )}
                                />
                                <Route
                                    path="/NewsArchive"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" breadcrumbs="false" homeComponent={NewsArchive} />
                                    )}
                                />
                                <Route
                                    path="/NewsDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={NewsDetails} showslider="false" />
                                    )}
                                />
                                <Route
                                    path="/PhotoGalery/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={PhotoGalery} />
                                    )}
                                />
                                <Route
                                    path="/PhotoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={PhotoGaleryDetails} showslider="false" />
                                    )}
                                />
                                <Route
                                    path="/VideoGalery/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={VideoGalery} />
                                    )}
                                />
                                <Route
                                    path="/VideoGaleryDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={VideoGaleryDetails} showslider="false" />
                                    )}
                                />
                                <Route
                                    path="/Contact/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" hasSidebar="false" homeComponent={Contact} showslider="false" />
                                    )}
                                />
                                <Route
                                    path="/FAQ/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" hasSidebar="false" homeComponent={Faq} />
                                    )}
                                />
                                <Route
                                    path="/Personel/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" sidebarPosition="start" homeComponent={Personel} />
                                    )}
                                />
                                <Route
                                    path="/PersonelDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" sidebarPosition="start" homeComponent={PersonelDetails} showslider="false" />
                                    )}
                                />
                                <Route
                                    path="/Search/:slug"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" breadcrumbs="false" homeComponent={Search} showslider="false" />
                                    )}
                                />
                                <Route
                                    path="/Frequency/:id"
                                    render={(props) => (
                                        <Layout
                                            {...props}
                                            headerLayout="default"
                                            breadcrumbs="false"
                                            homeComponent={FrequencySearch}
                                            showslider="false"
                                            hasSidebar="false"
                                        />
                                    )}
                                />
                                <Route
                                    path="/404"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={SitePageNotFound} showslider="false" />
                                    )}
                                />

                                <Route
                                    path="/AboutUs/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={RrethArkep} />
                                    )}
                                />
                                <Route path="/error">
                                    <SitePageError />
                                </Route>
                                <Redirect to="/" />
                            </Switch>
                        </ScrollContext>
                    </HelmetProvider>
                </BrowserRouter>
            </IntlProvider>
        );
    }
}

Root.propTypes = {
    /** current locale */
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

const mapDispatchToProps = {
    localeChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
