import {
    React,
    useState,
    useEffect,
    Fragment,
} from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';
// import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { getData } from '../../services/fetch-service';

// data stubs
import theme from '../../data/theme';
import useToggle from '../header/useToggle';
import FaqAnswers from './FaqAnswers';

function SitePageFaq(props) {
    const { locale } = props;
    const [allLinks, setAllLinks] = useState([]);
    const [id, setId] = useState(0);
    const [value, toggleValue] = useToggle(false);
    const params = useParams();

    useEffect(() => {
        getData(`FAQ/GetFAQ?PageId=${params.id}&Gjuha=${locale}&GroupId=0`).then((response) => {
            setAllLinks(response.data);
            if (response.data.length === 1) {
                setId(response.data[0].faqGrupiId);
                toggleValue(true);
            }
        });
    }, [locale, params.id]);

    function handleKeyDown(e) {
        e.preventDefault();
    }

    const linksList = allLinks.length > 1 && allLinks.map((item, index) => (
        <div className="faq-box" key={index}>
            <div
                className="faq-box-link"
                onKeyDown={handleKeyDown}
                role="button"
                tabIndex={0}
                onClick={() => {
                    setId(item.faqGrupiId);
                    toggleValue(true);
                }}
            >
                <img src={`/images/faq/faq${item.faqGrupiId}.png`} alt="" />
                <h6>{item.faqGrupiTitulli}</h6>
            </div>
        </div>
    ));

    return (
        <Fragment>
            <Helmet>
                <title>{`${theme.name_shq}`}</title>
            </Helmet>

            <div className="block faq">
                <div className="container">
                    <div className="faq-header">
                        {allLinks.length === 1 ? (
                            <h1 className="Faq-section-title">{allLinks[0].faqGrupiTitulli}</h1>
                        ) : (
                            <h1 className="Faq-section-title"><FormattedMessage id="faq" defaultMessage="FAQ" /></h1>
                        )}

                        <Link
                            id="submit"
                            to="/Contact/8"
                            type="submit"
                            className="btn btn-primary docs-search-btn"
                        >
                            <FormattedMessage id="Contact.name" defaultMessage="Kontakti" />
                        </Link>
                    </div>
                    <div className="faq__section">
                        {allLinks.length > 1 && (
                            <div className="box-faq">
                                {linksList}
                            </div>
                        )}
                        {value && (
                            <Fade big>
                                <div className="faq-questioins">
                                    <FaqAnswers id={id} />
                                </div>
                            </Fade>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(SitePageFaq);
