// react
import {
    React,
    useState,
    useEffect,
    Fragment,
    } from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// third-party
// import AppLink from '../shared/AppLink';
import { getData } from '../../services/fetch-service';
// // import eaplikimi from '../../img/logo-ballin.png';
// import autorizimet from '../../img/parallax-photo/autorizimet.png';
// // import konsultimet from '../../img/parallax-photo/konsultimet.png';
// import konsumatoret from '../../img/parallax-photo/konsumatoret.png';
// import kualiteti from '../../img/parallax-photo/kualiteti.png';
// import njohja from '../../img/parallax-photo/njohja.png';
// // import operatoret from '../../img/parallax-photo/operatoret';
// import roaming from '../../img/parallax-photo/roaming.png';
// import tregu from '../../img/parallax-photo/tregu.png';

// import PropTypes from 'prop-types';
// application
// import thesari from '../../img/Sliderbox/thesari.png';
// import budget from '../../img/Sliderbox/budget.png';
// import tatimi from '../../img/Sliderbox/tatimi.png';
// import partnership from '../../img/Sliderbox/partnership.png';
// import socialet from '../../img/Sliderbox/socialet.png';
// import puna from '../../img/Sliderbox/puna.png';
// import raportet from '../../img/Sliderbox/raportet.png';

function BlockPhotoMenu(props) {
    const { locale } = props;
    const [allLinks, setAllLinks] = useState([]);

    useEffect(() => {
        getData(`Home/GetMenus?&Gjuha=${locale}&LocationMenuID=6&ParentMenuID=0&skip=0&take=5`).then((response) => {
            setAllLinks(response.data);
        })
    }, []);
    const linksList = allLinks.map((item, index) => {
        const path = process.env.REACT_APP_FILESPATH;
        const image = `${path}${item.media[0]?.mediaEmri}${item.media[0]?.mediaEx}`;
        return (
            <li key={index}>
                {item.hasChild === true ? (
                    <div className="slider-box-link slider-box-link-childs">
                        <img src={image} alt="" />
                        <div className="link-childs-list">
                            <div className="and-devider">
                                <Link to={item.url}>
                                    {item.pageName}
                                </Link>
                                <p><FormattedMessage id="and" defaultMessage="dhe" /></p>
                            </div>

                            {item.submenu.map((submenu, key) => {
                                return (
                                    <Link key={key} to={submenu.url}>
                                        {submenu.pageName}
                                    </Link>
                                );
                            })}
                        </div>

                    </div>
                )
                    : (
                        <Link className="slider-box-link-photo" to={item.url}>
                            <img src={image} alt="" />
                            {item.pageName}
                        </Link>
                    )}
            </li>
        );
    });

    return (
        <Fragment>
        <div className="photo-menu-home">
        <div className="block-slideshow__slide-content">
            <ul className="slider-boxes-photo">
                {linksList}
            </ul>
        </div>
        </div>
        </Fragment>
    );
}

// BlockAplikoPune.propTypes = {
//     // locale: PropTypes.string,
// };

// BlockAplikoPune.defaultProps = {
//     // layout: 'classic',
// };

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockPhotoMenu);
