// react
import React from 'react';

// third-party
// import { FormattedMessage } from 'react-intl';

// application
import NavPanel from './NavPanel';
import Topbar from './Topbar';

function Header() {
    return (
        <div className="site-header">
          <Topbar />
            <div className="site-header__nav-panel">
                <NavPanel layout="default" />
            </div>

        </div>
    );
}

export default Header;
