import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import BlockSlideBox from '../../blocks/BlockSlideBox';
import BasicPage from '../page/BasicPage';
import RrethArkepDocsAndLinksHome from './RrethArkepDocsAndLinksHome';
import PersonelCardRrethArkep from './PersonelCardRrethArkep';

function RrethArkep() {
    return (
        <React.Fragment>
            <div className="only-title-home">
                {useMemo(() => <BasicPage layout="classic" showTitle={false} />, [])}
            </div>

            <div className="about-page-section">
                {useMemo(() => <BlockSlideBox location="aboutus" />, [])}
                {useMemo(() => <PersonelCardRrethArkep />, [])}
                {useMemo(() => <RrethArkepDocsAndLinksHome layout="classic" />, [])}
            </div>
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(RrethArkep);
