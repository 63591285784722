export default function getMenuLocation(menuLocationId) {
    let template = '';

    if (menuLocationId === 1 || menuLocationId === 2) {
        template = 'default';
    } else if (menuLocationId === 5) {
        template = 'default';
    } else if (menuLocationId === 6) {
        template = 'thesari';
    } else if (menuLocationId === 7) {
        template = 'buxheti';
    } else if (menuLocationId === 8) {
        template = 'tatimiNeProne';
    } else if (menuLocationId === 9) {
        template = 'partneriteti';
    } else if (menuLocationId === 10) {
        template = 'socialet';
    } else if (menuLocationId === 11) {
        template = 'puna';
    } else if (menuLocationId === 12) {
        template = 'raportimet';
    } else if (menuLocationId === 13) {
        template = 'pensionet';
    } else if (menuLocationId === 14) {
        template = 'punesimet';
    } else {
        template = '';
    }
    return template;
}
