// react
import React from 'react';
import Moment from 'moment';
// third-party
import classNames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

function NewsCard(props) {
    const { post, locale } = props;
    const path = process.env.REACT_APP_FILESPATH;
    const image = `${path}${post.media?.mediaEmri}${post.media?.mediaEx}`;
    const cardClasses = classNames('news-box');
    return (
        <div className={cardClasses}>
            <Link to={post.url}>
            <div className="new-thumb">
                {
                    post.media === null ? (
                        <img className="news-img-default" src="/images/blur/solid.jpg" alt="" />
                    ) : (
                        <LazyLoadImage
                            alt=""
                            effect="blur"
                            placeholderSrc="/images/blur/solid.jpg"
                            src={post.media?.otherSource === null ? image : post.media?.otherSource}
                        />
                    )
                }
            </div>
            </Link>
            <div className="news-box-content">
                <div className="new-txt">
                    <div className="post-card__namee">
                        <Link to={post.url}>{post.postimiTitulli}</Link>
                    </div>
                    <ul className="news-metaa lajme-lista-date">
                        <li>
                            <i className="far fa-calendar-alt" />
                            &nbsp;
                            <p className="date-lajme">
                                {Moment(post.postimiDataFillimit).locale(locale).format('LL')}
                            </p>
                        </li>
                    </ul>
                    <div className="post-card__contentt">
                        <p>
                            {post.postimiPershkrimi}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

// NewsCard.propTypes = {
//     /**
//      * post data object
//      */
//     // post: PropTypes.array,
//     /**
//      * post card layout
//      * one of ['grid-nl', 'grid-lg', 'list-nl', 'list-sm']
//      */
//     // layout: PropTypes.oneOf(['grid-nl', 'grid-nl-4', 'grid-lg', 'list-nl', 'list-sm']),
// };

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(NewsCard);
