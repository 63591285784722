import React from 'react';
import Skeleton from '@mui/material/Skeleton';

export default function SkletonNews() {
    return (
        <div className="lajmet-grid skeleton-grid">
            <div className="news-box">
                <div className="new-thumb-skeleton">
                    <Skeleton variant="rectangular" />
                </div>
                <div className="news-box-content">
                    <div className="new-txt">
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <ul className="news-meta lajme-lista-date">
                            <li>
                                <Skeleton animation="wave" />
                                &nbsp;
                                <Skeleton animation="wave" />
                            </li>
                        </ul>
                        <div className="post-card__content">
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="news-box">
                <div className="new-thumb-skeleton">
                    <Skeleton variant="rectangular" />
                </div>
                <div className="news-box-content">
                    <div className="new-txt">
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <ul className="news-meta lajme-lista-date">
                            <li>
                                <Skeleton animation="wave" />
                                &nbsp;
                                <Skeleton animation="wave" />
                            </li>
                        </ul>
                        <div className="post-card__content">
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="news-box">
                <div className="new-thumb-skeleton">
                    <Skeleton variant="rectangular" />
                </div>
                <div className="news-box-content">
                    <div className="new-txt">
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <ul className="news-meta lajme-lista-date">
                            <li>
                                <Skeleton animation="wave" />
                                &nbsp;
                                <Skeleton animation="wave" />
                            </li>
                        </ul>
                        <div className="post-card__content">
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="news-box">
                <div className="new-thumb-skeleton">
                    <Skeleton variant="rectangular" />
                </div>
                <div className="news-box-content">
                    <div className="new-txt">
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <ul className="news-meta lajme-lista-date">
                            <li>
                                <Skeleton animation="wave" />
                                &nbsp;
                                <Skeleton animation="wave" />
                            </li>
                        </ul>
                        <div className="post-card__content">
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="news-box">
                <div className="new-thumb-skeleton">
                    <Skeleton variant="rectangular" />
                </div>
                <div className="news-box-content">
                    <div className="new-txt">
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <ul className="news-meta lajme-lista-date">
                            <li>
                                <Skeleton animation="wave" />
                                &nbsp;
                                <Skeleton animation="wave" />
                            </li>
                        </ul>
                        <div className="post-card__content">
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="news-box">
                <div className="new-thumb-skeleton">
                    <Skeleton variant="rectangular" />
                </div>
                <div className="news-box-content">
                    <div className="new-txt">
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <ul className="news-meta lajme-lista-date">
                            <li>
                                <Skeleton animation="wave" />
                                &nbsp;
                                <Skeleton animation="wave" />
                            </li>
                        </ul>
                        <div className="post-card__content">
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
