import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Fade from 'react-reveal/Fade';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    TablePagination,
    TableContainer,
} from '@mui/material';
import { Select } from '@mantine/core';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { getData } from '../../../services/fetch-service';

function FrequencySearch(props) {
    const { locale } = props;
    const [rangeFromValue, setRangeFromValue] = useState('1');
    const [rangeToValue, setRangeToValue] = useState('999');
    const [selectedOption, setSelectedOption] = useState('2');
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(0);
    const [PageText, setPageText] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [allocations, setAllocations] = useState([]);
    const [selectedAllocation, setSelectedAllocation] = useState('0');
    const [aplications, setApplications] = useState([]);
    const [selectedAplcation, setSelectedAplication] = useState('0');
    const params = useParams();

    const fetchData = () => {
        if (rangeFromValue && rangeToValue && selectedOption) {
            getData(`RadioFrequenc/Search?rangeFrom=${rangeFromValue}&rangeTo=${rangeToValue}&allocationId=${selectedAllocation}&applicationId=${selectedAplcation}&typeId=${selectedOption}`)
                .then((response) => {
                    setTableData(response.data);
                })
                .catch(() => {
                    setTableData([]);
                });
        }
    };

    useEffect(() => {
        getData('RadioFrequenc/GetAllocations').then((response) => {
            setAllocations(response.data);
        });
        getData('RadioFrequenc/GetApplications').then((response) => {
            setApplications(response.data);
        });
        getData(`Page/GetBasicPage?PageID=${params.id}&Gjuha=${locale}`).then((response) => {
            setPageText(response.data[0]);
        })
    }, []);

    const handleRangeFromChange = (e) => {
        setRangeFromValue(e.target.value);
    };

    const handleRangeToChange = (e) => {
        setRangeToValue(e.target.value);
    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    const sendData = () => {
        if (rangeFromValue && rangeToValue && selectedOption) {
            fetchData();
        } else {
            toast.error('Please enter a range', { theme: 'colored' });
        }
    };

    let placeholderText;
    if (locale === 'sq') {
        placeholderText = 'Kërko';
    } else if (locale === 'en') {
        placeholderText = 'Search';
    } else if (locale === 'sr') {
        placeholderText = 'Pretraga';
    }

    const AllocationOptions = allocations.map((allocation) => ({
        value: allocation.id,
        label: allocation.name,
    }));
    const AplicationOptions = aplications.map((aplication) => ({
        value: aplication.id,
        label: aplication.name,
    }));

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage);

    return (
        <div className="frequency-block">
            <div className="frequency-section">
                <div className="frequency-table mt-55">
                    <div className="frequency-range">
                        <h5>{PageText.pageName}</h5>
                        <div className="range-inputs">
                            <div className="docs-grid frequency-input">
                                <span><FormattedMessage id="from" defaultMessage="Prej" /></span>
                                <input
                                    type="text"
                                    id="rangeFrom"
                                    name="rangeFrom"
                                    placeholder={placeholderText}
                                    value={rangeFromValue}
                                    onChange={handleRangeFromChange}
                                    className="form-control form-docs search-filter"
                                />
                            </div>
                            <div className="docs-grid frequency-input">
                                <span><FormattedMessage id="to" defaultMessage="Deri" /></span>
                                <input
                                    type="text"
                                    id="rangeTo"
                                    name="rangeTo"
                                    placeholder={placeholderText}
                                    value={rangeToValue}
                                    onChange={handleRangeToChange}
                                    className="form-control form-docs search-filter"
                                />
                            </div>
                            <select
                                className="form-control form-control-sm form-docs category-select"
                                value={selectedOption}
                                onChange={handleOptionChange}
                            >
                                <option value="1">Hz</option>
                                <option value="2">kHz</option>
                                <option value="3">MHz</option>
                                <option value="4">GHz</option>

                            </select>
                        </div>
                    </div>
                    <div className="frequency-filter">
                        <Select
                            label="Allocations"
                            placeholder="Select Allocation"
                            searchable
                            nothingFound="No options"
                            data={AllocationOptions}
                            value={selectedAllocation}
                            onChange={(value) => {
                                if (value === null) {
                                    // Clear action
                                    setSelectedAllocation('0');
                                } else {
                                    setSelectedAllocation(value);
                                }
                            }}
                            clearable
                        />

                        <Select
                            label="Applications"
                            placeholder="Select Application"
                            searchable
                            nothingFound="No options"
                            data={AplicationOptions}
                            value={selectedAplcation}
                            onChange={(value) => {
                                if (value === null) {
                                    // Clear action
                                    setSelectedAplication('0');
                                } else {
                                    setSelectedAplication(value);
                                }
                            }}
                            clearable
                        />
                    </div>
                    <div className="frequency-search">
                        <button type="button" onClick={sendData}>{placeholderText}</button>
                    </div>
                </div>
                {PageText.pageText !== '' && (
                    <div className="frequency-page-text">
                        <div
                            dangerouslySetInnerHTML={{ __html: PageText.pageText }}
                            className="post__content typography"
                        />
                    </div>
                )}
            </div>
            {tableData.length > 0 && (
                <Fade>
                    <div className="frequency-Data">
                        <Paper style={{ height: 'auto', width: '100%' }}>
                            <TableContainer sx={{ maxHeight: '100%' }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Frequency band</TableCell>
                                            <TableCell>Allocation</TableCell>
                                            <TableCell>Allocation Status</TableCell>
                                            <TableCell>Allocation Comments</TableCell>
                                            <TableCell>Application</TableCell>
                                            <TableCell>Application Status</TableCell>
                                            <TableCell>Application Comments</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                            <TableRow hover key={row.id}>
                                                <TableCell>
                                                    {row.lowerFrequency}
                                                    -
                                                    {row.higherFrequency}
                                                    &nbsp;
                                                    {row.type}
                                                </TableCell>
                                                <TableCell>{row.allocationTerm}</TableCell>
                                                <TableCell>{row.allocationStatus}</TableCell>
                                                <TableCell>{row.allocationComments}</TableCell>
                                                <TableCell>{row.applicationTerm}</TableCell>
                                                <TableCell>{row.applicationStatus}</TableCell>
                                                <TableCell>{row.applicationComments}</TableCell>
                                            </TableRow>
                                        ))}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={3} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {/* Pagination */}
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 30, { value: -1, label: 'All' }]}
                                component="div"
                                count={tableData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </div>
                </Fade>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(FrequencySearch);
