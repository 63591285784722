// react
import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// third-party
// import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getData } from '../../../services/fetch-service';
import ProductGallery from '../../shared/ProductGallery';

function FotoGaleryDetailsPost(props) {
    const { layout } = props;
    const params = useParams();
    const url = window.location.pathname.split('/').pop();
    const [galeria, setGaleria] = useState([]);
    const [Photos, setMediaPostimet] = useState([]);
    useEffect(() => {
        getData(`Galery/GetGaleryDetails?MediaGaleriaID=${params.postimiId}`).then((response) => {
            setGaleria(response.data[0]);
            setMediaPostimet(response.data[0].media)
        });
    }, [url]);
    return (
        <div className={`block post post--layout--${layout}`}>
            <div className={`post__header post-header post-header--layout--${layout}`}>

                <div>
                    <h1 className="post-header__title">
                        {galeria.mediaGaleriaPershkrimi}
                    </h1>
                </div>
                <ProductGallery images={Photos} />
            </div>
        </div>
    );
}

FotoGaleryDetailsPost.propTypes = {
    /**
     * post layout
     * one of ['classic', 'full'] (default: 'classic')
     */
    // locale: PropTypes.string,
    layout: PropTypes.oneOf(['classic', 'full']),
};
FotoGaleryDetailsPost.defaultProps = {
    layout: 'classic',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(FotoGaleryDetailsPost);
