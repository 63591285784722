// react
import React, { Component } from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import Slider from 'react-slick';
// import { FormattedMessage } from 'react-intl';
// import { Link } from 'react-router-dom';

// application
// import BlockHeader from '../shared/BlockHeader';
import PostCardSecond from '../shared/PostCardSecond';
import { getData } from '../../services/fetch-service';

class SecondNews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            post: [],
        };
    }

    componentDidMount() {
        const { locale } = this.props;
        getData(`News/GetNews?Gjuha=${locale}&PostimiKategoriaID=1&skip=1&take=4&TitulliLength=60&PermbajtjaLength=175`).then((response) => {
            this.setState({
                post: response.data.lajmetLista,
            });
        });
    }

    handleNextClick = () => {
        if (this.slickRef) {
            this.slickRef.slickNext();
        }
    };

    handlePrevClick = () => {
        if (this.slickRef) {
            this.slickRef.slickPrev();
        }
    };

    setSlickRef = (ref) => {
        this.slickRef = ref;
    };

    render() {
        const { layout } = this.props;
        const { post } = this.state;
        const postsList = post.map((post) => <PostCardSecond key={post.postimiId} post={post} />);

        return (
            <div className={`block block-posts block-posts--layout--${layout}`} data-layout={layout}>
                <div className="block-news">
                    {/* <div className="news-title">
                        <Link className="event-btn" to="/News"><h3><FormattedMessage id="news-home-title" defaultMessage="Lajmet" /></h3></Link>
                        <div className="hometitle-devider" />
                        <div className="home-title-button">
                            <Link className="event-btn" to="/News"><FormattedMessage id="About-button" defaultMessage="Example Text" /></Link>
                        </div>
                    </div> */}
                    <div className="block-posts__news">
                        {postsList}
                    </div>
                    {/* <BlockHeader
                        arrows
                        onNext={this.handleNextClick}
                        onPrev={this.handlePrevClick}
                    /> */}
                </div>
            </div>
        );
    }
}

SecondNews.propTypes = {
    // title: PropTypes.string.isRequired,
    layout: PropTypes.oneOf(['list-sm', 'grid-nl', 'grid-nl-4']),
    locale: PropTypes.string,
};

SecondNews.defaultProps = {
    layout: 'list-sm',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(SecondNews);
