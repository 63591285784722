import React from 'react';

// third-party
import PropTypes from 'prop-types';
import MobileMenu from './MobileMenu';

function mobilePanel() {
    return (
        <MobileMenu layout="default" />
    );
}

mobilePanel.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(['default', 'about']),
};

mobilePanel.defaultProps = {
    layout: 'default',
};

export default mobilePanel;
