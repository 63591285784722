// react
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getData } from '../../services/fetch-service';

function FooterBottomLinks(props) {
    const { locale } = props;
    const [footerBottom, setFooterBottom] = useState([]);
    useEffect(() => {
        getData(`Home/GetMenusWithoutChilds?Gjuha=${locale}&LocationMenuID=12`).then((response) => {
            setFooterBottom(response.data);
        })
    }, []);

    const linksList = footerBottom.map((item) => (
        <Link to={item.url}>
            {item.pageName}
        </Link>
    ));

    return (
        <div className="footer_pages_color footer_pages_order">
            {linksList.length > 0 ? linksList : ''}
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(FooterBottomLinks);
