// react
import React from 'react';
// third-party
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

function SitePageNotFound() {
    const history = useHistory();
    return (
        <div className="error404">
            <div className="block error-box">
                <div className="container">
                    <div className="not-found">
                        <div className="not-found__404">
                            Oops! Error 404
                        </div>

                        <div className="not-found__content">
                            <h1 className="not-found__title"><FormattedMessage id="NotFound.PageNotFound" defaultMessage="" /></h1>

                            <button
                                type="button"
                                onClick={() => history.go(-2)}
                                className="btn btn-primary btn-about"
                            >
                                <FormattedMessage id="NotFound.BackBtn" defaultMessage="" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageNotFound;
