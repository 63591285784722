// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';

// application
import AppLink from '../shared/AppLink';
import SubMenuList from './SubMenuList';
// import { ArrowRoundedRight6x9Svg } from '../../svg';
import Collapse from '../shared/Collapse';
import { ArrowRoundedDown9x6Svg } from '../../svg';

function Menu(props) {
    const {
        layout,
        withIcons,
        items,
        onClick,
    } = props;

    const renderLink = (item, content) => {
        let link;

        if (item.url && item.otherSource === false) {
            link = (
                <AppLink
                    className="colum-title"
                    {...item.props}
                    to={item.url}
                    onClick={() => onClick(item)}
                >
                    {content}
                </AppLink>
            );
        } else if (item.url && item.otherSource === true) {
            link = (
                <a className="colum-title" href={item.url} target={item.targeti}>
                    <span>
                        {item.pageName}
                    </span>
                </a>
            );
        } else {
            link = <button type="button" onClick={() => onClick(item)}>{content}</button>;
        }

        return link;
    };

    const itemsList = items.map((item, index) => {
        const renderCategory = (
            {
                toggle, setItemRef, setContentRef,
            },
        ) => {
            let expander;
            let arrow;
            let submenu;
            let icon;

            // if (item.submenu && item.submenu.length) {
            //     arrow = <ArrowRoundedRight6x9Svg className="menu__arrow" />;
            // }

            if (item.submenu && item.submenu.length) {
                expander = <ArrowRoundedDown9x6Svg width="20px" height="20px" className="widget-categories__arrow" onClick={toggle} />;
                submenu = (
                    <div className="colon-childs" ref={setContentRef}>
                        <SubMenuList items={item.submenu} onClick={() => onClick(item)} />
                    </div>
                );
            }

            if (withIcons && item.icon) {
                icon = (
                    <div className="menu__icon">
                        <img src={item.icon} srcSet={item.icon_srcset} alt="" />
                    </div>
                );
            }

            const classesMegaMenu = classNames(item.isMegaMenu === true ? 'col-md-4 colum-box' : 'col colum-box');
            return (
                <div className={classesMegaMenu} key={index} ref={setItemRef}>
                    <div className="colum-content">
                        {renderLink(item, (
                            <React.Fragment>
                                {icon}
                                {/* {item.title} */}
                                {item.pageName}
                                {arrow}
                            </React.Fragment>
                        ))}
                        <div>
                            {expander}
                        </div>
                    </div>
                    {submenu}
                </div>
            );
        };
        return <Collapse key={index} toggleClass="menu__item--open" render={renderCategory} />;
    });

    const classes = classNames(`row menu menu--layout--${layout}`, {
        'menu--with-icons': withIcons,
    });

    return (
        <div className={classes}>
            <div className="col-md-12 mega-colums">
                {itemsList}
            </div>
        </div>
    );
}

Menu.propTypes = {
    /** one of ['classic', 'topbar'] (default: 'classic') */
    layout: PropTypes.oneOf(['classic', 'topbar']),
    /** default: false */
    withIcons: PropTypes.bool,
    /** array of menu items */
    items: PropTypes.array,
    /** callback function that is called when the item is clicked */
    onClick: PropTypes.func,
};

Menu.defaultProps = {
    layout: 'classic',
    withIcons: false,
    items: [],
    onClick: () => { },
};

export default Menu;
