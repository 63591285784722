// react
import React, { useState, useEffect, Fragment } from 'react';
import Moment from 'moment';

// third-party
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
// import { FormattedMessage } from 'react-intl';
// application
import { getData } from '../../../services/fetch-service';
// import calendar from '../../../img/Events/calendar-event.png';
// import ThesariLinksBox from './ThesariLinksBox';

function RrethArkepDocsAndLinksHome(props) {
    // const { layout } = props;
    const { locale } = props;
    const [doc, setDoc] = useState([]);
    // const [linkBox, setLinkBox] = useState([]);
    // const [eventet, setEventet] = useState([]);
    // const [DocName, setDocName] = useState([]);
    // const [ngjarjetName, setNgjarjetName] = useState([]);

    useEffect(() => {
        getData(`Page/GetPageMedia?PageID=25&Gjuha=${locale}&skip=0&take=5&TitulliLength=100&PermbajtjaLength=100`).then((response) => {
            setDoc(response.data.mediaLista);
        });
        // getData(`SideBar/GetMenus?Gjuha=${locale}&LocationMenuID=27`).then((response) => {
        //     setLinkBox(response.data);
        // });
    }, []);

    const docLista = doc.map((item, index) => {
        const path = process.env.REACT_APP_FILESPATH;
        const iconpath = process.env.REACT_APP_ICONSPATH;
        const link = item.media.otherSource === null ? `${path}${item.media.mediaEmri}${item.media.mediaEx}` : item.media.otherSource;
        const iconslink = `${iconpath}${item.fileIco.mediaExPath}`;
        return (
            <div key={index} className="file_manager">
                <div className="card-docs">
                    <div className="item-docs home-item-docs">
                        <Link to={item.url} className="icon-docs">
                            <img src={iconslink} alt="" width="40px" />
                        </Link>
                        {item.media.otherSource === null ? (
                            <div className="file-name">
                                <a href={link} target="_blank" download={item.pageMedia.pershkrimi.replaceAll('.', '')} rel="noreferrer">{item.pageMedia.pershkrimi}</a>
                                <div className="file-date file-date-docs">
                                    {Moment(item.pageMedia.dataFillimit).format('DD/MM/YYYY')}
                                </div>
                            </div>
                            ) : (
                            <div className="file-name">
                                <a href={link} target="_blank" rel="noreferrer">{item.pageMedia.pershkrimi}</a>
                                <div className="file-date file-date-docs">
                                    {Moment(item.pageMedia.dataFillimit).format('DD/MM/YYYY')}
                                </div>
                            </div>
                            )}
                    </div>
                </div>

            </div>
        );
    });

    // const boxes = linkBox.map((item, index) => {
    //     return (
    //         <Fragment key={index}>
    //             <div className="small-side-box full-side-box">
    //                 {item.otherSource !== true ? (
    //                     <Link to={item.url}>
    //                         <div className="small-side-box-text">
    //                             {item.pageName}
    //                         </div>
    //                     </Link>
    //                 ) : (
    //                     <a href={item.url} target={item.targeti}>
    //                         <div className="small-side-box-text">
    //                             {item.pageName}
    //                         </div>
    //                     </a>
    //                 )}
    //             </div>
    //         </Fragment>
    //     );
    // });

    return (
        <Fragment>
            {(doc.length > 0) ? (
                        <div className="row row-50 flex-lg-row ">
                            {doc.length > 0 ? (
                                <div className="col-md-12 col-lg-12 col-xl-12 event-box gap-10">
                                    <div className="event-first-title">
                                        <h2><FormattedMessage id="Publikime" defaultMessage="Publikime" /></h2>
                                    </div>
                                    {docLista}
                                    <div className="event-button">
                                        <Link className="event-btn" to="/Page/25"><FormattedMessage id="About-button" defaultMessage="Lexo më shumë" /></Link>
                                    </div>
                                </div>
                            ) : ('')}
                            {/* {linkBox.length > 0 ? (
                                <div className="col-md-12 col-lg-6 col-xl-4 event-date-box full-link-cols">
                                    <div className="small-side-box-docs full-side-box-docs">
                                        <div className="small-side-box-list full-links-home">
                                            {boxes}
                                        </div>
                                    </div>
                                </div>
                            ) : ('')} */}
                        </div>
            ) : ('')}

        </Fragment>
    );
}

// RrethArkepDocsAndLinksHome.propTypes = {
//     layout: PropTypes.oneOf(['classic', 'boxed', 'sidebar', 'fullpage-satistics']),
// };

// RrethArkepDocsAndLinksHome.defaultProps = {
//     layout: 'classic',
// };

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(RrethArkepDocsAndLinksHome);
