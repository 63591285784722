// react
import React, { useState, useEffect } from 'react';

// third-party
// import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// application
import { connect } from 'react-redux';
import { getData } from '../../services/fetch-service';
// import HowDoI from './howDoI';
import Search from './Search';
import DropdownLanguage from './DropdownLanguage';
import SocialLinks from '../shared/SocialLinks';

function Topbar(props) {
    const { locale } = props;
    const [toplinks, setTopbar] = useState([]);

    useEffect(() => {
        getData(`Home/GetMenusWithoutChilds?Gjuha=${locale}&LocationMenuID=8`).then((response) => {
            setTopbar(response.data);
        });
    }, []);

    const topbarLinks = toplinks.map((item, index) => {
        return (
            <div key={index} className="topbar-box-content">
                <div className="nav-links__item-topbar">
                    <Link className="topbar-item" to={item.url}>
                        {item.pageName}
                    </Link>
                </div>
            </div>
        );
    });

    return (
        <div className="site-header__middle">
            <div className="site-nav-topbar">
                {topbarLinks}
                {/* <div className="topbar-box-content">
                    <div className="topbar__item">
                        <DropdownLanguage />
                    </div>
                </div> */}
                {/* <div className="topbar-box-content">
                    <HowDoI />
                </div> */}
            </div>
            <div className="topbar-left-box">
                <div className="search-topbar">
                        <Search context="mobile-header" />
                </div>
                <div className="">
                        <DropdownLanguage />
                </div>
                <span className="span-topbar" />
                <div className="topbar-socials">
                        <SocialLinks shape="circle" />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(Topbar);
