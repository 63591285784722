// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FotoGaleryDetailsPost from './FotoGaleryDetailsPost';

function FotoGaleryDetails(props) {
    const { layout } = props;
    let content;
    if (layout === 'classic') {
        content = (
            <FotoGaleryDetailsPost layout={layout} />
        );
    } else if (layout === 'full') {
        content = (
            <div className="row justify-content-center">
                <div className="col-md-12 col-lg-12 col-xl-8">
                    <FotoGaleryDetailsPost layout={layout} />
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            {content}
        </React.Fragment>
    );
}

FotoGaleryDetails.propTypes = {
    layout: PropTypes.oneOf(['classic', 'full']),
};

FotoGaleryDetails.defaultProps = {
    layout: 'classic',
};
const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(FotoGaleryDetails);
