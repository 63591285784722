// react
import React from 'react';

export default function PostLoader() {
    return (
        <div className="block-loader post-loader">
            <div className="block-loader__spinner post-spinner" />
        </div>
    );
}
