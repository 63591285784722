export default {
    name_shq: 'Autoriteti Rregullativ i KomunikimeveElektronike dhe Postare',
    name_en: 'Regulatory Authority of Electronic and Postal Communications',
    name_sr: 'Regulatorni Organ Za Elektronske I Poštanske Komunikacije',
    fullName: 'Autoriteti Rregullativ i KomunikimeveElektronike dhe Postare',
    url: '',
    author: {
        name: '',
        profile_url: '',
    },
    contacts: {
        address: 'Ndërtesa e Qeverisë, Kati 11, Rruga Nënë Tereza, Prishtinë',
        email: 'antoneta.azemi@rks-gov.net',
        phone: '038/ 200 34 104',
    },
};
