// react
import {
    React,
    useState,
    useEffect,
    Fragment,
    } from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// third-party
// import AppLink from '../shared/AppLink';
import { getData } from '../../services/fetch-service';
// import eaplikimi from '../../img/logo-ballin.png';
import email from '../../img/gmail.png';
import pin from '../../img/placeholder.png';
import telefoni from '../../img/call.png';
// import PropTypes from 'prop-types';
// application
// import thesari from '../../img/Sliderbox/thesari.png';
// import budget from '../../img/Sliderbox/budget.png';
// import tatimi from '../../img/Sliderbox/tatimi.png';
// import partnership from '../../img/Sliderbox/partnership.png';
// import socialet from '../../img/Sliderbox/socialet.png';
// import puna from '../../img/Sliderbox/puna.png';
// import raportet from '../../img/Sliderbox/raportet.png';

function BlockContact(props) {
    const url = window.location.pathname.split('/').pop();
    // const history = useHistory();
    const params = useParams();
    const { locale } = props;
    const [ddlcontact, setDdlContact] = useState([]);
    useEffect(() => {
        getData(`Contact/GetContacts?PageID=${params.id}&Gjuha=${locale}`).then((response) => {
            setDdlContact(response.data[0]);
        });
    }, [url]);
    // const ddlContact = ddlc.map((item, index) => {
    //     const path = process.env.REACT_APP_FILESPATH;
    //     const image = `${path}${item.media[0]?.mediaEmri}${item.media[0]?.mediaEx}`;
    //     return (
    //         <li key={index}>
    //             {item.hasChild === true ? (
    //                 <div className="slider-box-link slider-box-link-childs">
    //                     <img src={image} alt="" />
    //                     <div className="link-childs-list">
    //                         <div className="and-devider">
    //                             <Link to={item.url}>
    //                                 {item.pageName}
    //                             </Link>
    //                             <p><FormattedMessage id="and" defaultMessage="dhe" /></p>
    //                         </div>

    //                         {item.submenu.map((submenu, key) => {
    //                             return (
    //                                 <Link key={key} to={submenu.url}>
    //                                     {submenu.pageName}
    //                                 </Link>
    //                             );
    //                         })}
    //                     </div>

    //                 </div>
    //             )
    //                 : (
    //                     <Link className="slider-box-link" to={item.url}>
    //                         <img src={image} alt="" />
    //                         {item.pageName}
    //                     </Link>
    //                 )}
    //         </li>
    //     );
    // });

    return (
        <Fragment>
        <div className="block-slideshow-contact__slide-content">
            <ul className="slider-boxes-contact">
                <li>
                        <a className="slider-box-link-contact" to="/">
                            <img src={pin} alt="" />
                            <h3><FormattedMessage id="Adresa" defaultMessage="Adresa" /></h3>
                            <p>{ddlcontact.adresa}</p>
                        </a>
                </li>
                <li>
                        <a href={`tel:${ddlcontact.telefoni}`} className="slider-box-link-contact" to="/">
                            <img src={telefoni} alt="" />
                            <h3><FormattedMessage id="Telefoni" defaultMessage="Telefoni" /></h3>
                            <p>{ddlcontact.telefoni}</p>
                        </a>
                </li>
                <li>
                        <a href={`mailto:${ddlcontact.emaili}`} className="slider-box-link-contact" to="/">
                            <img src={email} alt="" />
                            <h3><FormattedMessage id="Email" defaultMessage="Email" /></h3>
                            <p>{ddlcontact.emaili}</p>
                        </a>
                </li>
            </ul>
        </div>
        </Fragment>
    );
}

// BlockAplikoPune.propTypes = {
//     // locale: PropTypes.string,
// };

// BlockAplikoPune.defaultProps = {
//     // layout: 'classic',
// };

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockContact);
