import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageDocsFilterContent from './PageDocsFilterContent';

function PageDocsFilter(props) {
    const { layout } = props;
    let content;
    if (layout === 'classic') {
        content = (
            <PageDocsFilterContent layout={layout} />
        );
    } else if (layout === 'full') {
        content = (
            <div className="row justify-content-center">
                <div className="col-md-12 col-lg-8 col-xl-8">
                    <PageDocsFilterContent layout={layout} />
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            {content}
        </React.Fragment>
    );
}

PageDocsFilter.propTypes = {
    layout: PropTypes.oneOf(['classic', 'full']),
};

PageDocsFilter.defaultProps = {
    layout: 'classic',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(PageDocsFilter);
