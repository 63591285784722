// react
import {
    React,
    useState,
    useEffect,
    Fragment,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getData } from '../../../services/fetch-service';

function BasicPage(props) {
    const { layout } = props;
    const history = useHistory();
    const params = useParams();
    const postClasses = classNames('post__content typography', {
        'typography--expanded': layout === 'full',
    });
    const { locale } = props;
    const url = window.location.pathname.split('/').pop();
    const [Page, setPage] = useState([]);
    useEffect(() => {
        getData(`Page/GetBasicPage?PageID=${params.id}&Gjuha=${locale}`).then((response) => {
            setPage(response.data);
        }).catch(() => {
            history.push('/404');
        });
    }, [url]);
    return (

        <div className={`post post--layout--${layout}`}>
            <div className={`post__header post-header post-header--layout--${layout}`}>
                {
                    Page.map((page, key) => {
                        return (
                            <Fragment>
                                {page.pageText !== '' && (
                                    <div className="basic-page-text" key={key}>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: page.pageText }}
                                            className={postClasses}
                                        />
                                    </div>
                                )}

                            </Fragment>

                        );
                    })
                }
            </div>
        </div>

    );
}

BasicPage.propTypes = {
    /**
     * post layout
     * one of ['classic', 'full'] (default: 'classic')
     */
    locale: PropTypes.string,
    layout: PropTypes.oneOf(['classic', 'full']),
};
BasicPage.defaultProps = {
    layout: 'classic',
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BasicPage);
